<template>
  <div v-if="steps">
    <input
      type="range"
      :min="steps[0]"
      :max="steps[steps.length - 1]"
      :class="icon"
      :style="sliderStyles"
      @input="$emit('input', $event.target.value)"
      v-model="value"
      :step="steps[1] - steps[0]"
      list="stepslist"
    />
    <datalist v-if="steps" id="stepslist">
      <option v-for="step in steps" :key="step">{{ step }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 0,
    };
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
    },
  },
  computed: {
    sliderStyles() {
      return `background : linear-gradient(to right, #00b08d 0%, #00b08d ${
        ((this.value - this.steps[0]) /
          (this.steps[this.steps.length - 1] - this.steps[0])) *
        100
      }%, #DEE2E6 ${
        ((this.value - this.steps[0]) /
          (this.steps[this.steps.length - 1] - this.steps[0])) *
        100
      }%, #DEE2E6 100%) !important;
      border-radius: 10px !important;
`;
    },
  },
};
</script>

<style scoped>
input[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  max-width: 500px;
  outline: none;
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  border: none;
  height: 27px;
  width: 27px;
  background-color: transparent;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin-top: -1.1em;
  -webkit-appearance: none;
  box-shadow: none;
}
.airplane::-webkit-slider-thumb {
  background-image: url("../../assets/airplane-icon.png");
}
.car::-webkit-slider-thumb {
  background-image: url("../../assets/car-icon.png");
}
.shopping::-webkit-slider-thumb {
  background-image: url("../../assets/shopping-cart-icon.png");
}

.clothes::-webkit-slider-thumb {
  background-image: url("../../assets/clothes-icon.png");
}
.food::-webkit-slider-thumb {
  background-image: url("../../assets/food-icon.png");
}
.family::-webkit-slider-thumb {
  background-image: url("../../assets/family-icon.png");
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  /* background: #bdbdbd; */
  background: transparent;
  border-radius: 10px !important;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb:active {
  background-position: 100% 0px;
  transform: scale(1.2);
}
</style>