<template>
  <div class="question-container">
    <!-- question -->
    <div class="question-card">
      <div class="question-card-title">
        Is your home mostly powered by renewable energy?
      </div>
      <div class="question-card-description">
        For example, solar panels, wind turbine or biomass.
      </div>
    </div>
    <!-- choices -->
    <div class="choices-card">
      <v-btn
        class="yes-button"
        depressed
        :class="{
          'button-active': homePoweredByRE == true,
        }"
        @click="homePoweredByRE = true"
      >
        Yes
      </v-btn>
      <v-btn
        class="no-button"
        depressed
        :class="{
          'button-active': homePoweredByRE == false,
        }"
        @click="homePoweredByRE = false"
      >
        No
      </v-btn>
    </div>

    <v-btn
      class="submit-button"
      x-large
      depressed
      dark
      rounded
      @click="$router.push({ name: 'LoadingScreen' })"
      >See my carbon emissions</v-btn
    >
  </div>
</template>

<script>
import { mapGetters, mapMutations,mapActions } from "vuex";

export default {
  name: "Question8",
  methods: {
    ...mapMutations(["setUserInputs"]),
    ...mapActions(["calculateUserInputs"]),
    setHomePoweredByRE(homePoweredByRE) {
      this.homePoweredByRE = homePoweredByRE;
    },
    
    
  },
  computed: {
    ...mapGetters(["getUserInputs"]),
    homePoweredByRE: {
      get: function () {
        return this.getUserInputs.homePoweredByRE;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "homePoweredByRE", value: newVal });
      },
    },
  },
};
</script>
<style scoped>
.question-card {
  position: absolute;
  top: 16vh;
  left: 15px;
  right: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 16px;
  font-family: "League Spartan", sans-serif;
  width: 80%;
}

.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  margin: 10px 0px;
}
.question-card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}
.choices-card {
  position: absolute;
  top: 320px;
  right: 15px;
  line-height: 20px;
  margin-top: 20px;
  width: fit-content;
  font-family: "League Spartan", sans-serif;
}
.choices-card > button {
  padding: 36px !important;
  background: #f2f2f2;
  min-width: 90px !important;
  color: #bdbdbd;
  font-style: normal;
  font-weight: 900;
  font-size: 22.4583px;
  line-height: 25px;
  text-align: center;
}
.yes-button {
  border-radius: 20px 0px 0px 20px;
}
.no-button {
  border-radius: 0px 20px 20px 0px;
}
.submit-button {
  position: absolute;
  bottom: 60px;
  right: 15px;
  left: 15px;
  font-family: "League Spartan", sans-serif;

  background: linear-gradient(262.97deg, #00b08d 0%, #00e9bb 100%);
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 16px;
}
</style>
