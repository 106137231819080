<template>
  <div class="choices-card">
    <div v-if="title" class="choices-card-header">{{ title }}</div>
    <div class="choices-card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.choices-card {
  margin-top: 20px;

  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  width: fit-content;
  font-family: "League Spartan", sans-serif;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.choices-card-header {
  background-color: #333333;
  padding: 20px 20px;
  text-align: center;
  color: white;
  font-weight: 900;
  font-size: 18px;
  line-height: 16px;
}

.choices-card-content {
  padding: 16px;
}
.choices-card-content > div > button {
  padding: 24px 20px !important;
  margin: 6px 0px;
  border-radius: 16px;
  background: #f2f2f2;
  min-width: 180px !important;
  justify-content: start !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
</style>