<template>
  <div class="question-container">
    <!-- question -->
    <div class="question-card">
      <div class="question-card-title">Describe your diet:</div>
    </div>
    <!-- choices -->

    <div class="choices-card">
      <ChoiceButton
        :width="250"
        @click.native="setMeatFrequency('everyDay')"
        :isActive="meatFrequency == 'everyDay'"
      >
        🍖 I eat meat everyday
      </ChoiceButton>
      <ChoiceButton
        :width="250"
        @click.native="setMeatFrequency('often')"
        :isActive="meatFrequency == 'often'"
      >
        🥓 I eat meat often
      </ChoiceButton>
      <ChoiceButton
        :width="250"
        @click.native="setMeatFrequency('rarely')"
        :isActive="meatFrequency == 'rarely'"
      >
        🍗 I eat meat rarely
      </ChoiceButton>
      <ChoiceButton
        :width="250"
        @click.native="setMeatFrequency('pescatarian')"
        :isActive="meatFrequency == 'pescatarian'"
      >
        🍤 Pescatarian
      </ChoiceButton>
      <ChoiceButton
        :width="250"
        @click.native="setMeatFrequency('vegetarian')"
        :isActive="meatFrequency == 'vegetarian'"
      >
        🧀 Vegetarian
      </ChoiceButton>
      <ChoiceButton
        :width="250"
        @click.native="setMeatFrequency('vegan')"
        :isActive="meatFrequency == 'vegan'"
      >
        🥗 Vegan
      </ChoiceButton>
    </div>
  </div>
</template>

<script>
import ChoiceButton from "@/components/calculator/sub-components/ChoiceButton.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Question5",
  components: {
    ChoiceButton,
  },

  computed: {
    ...mapGetters(["getUserInputs"]),
    meatFrequency: {
      get: function () {
        return this.getUserInputs.meatFrequency;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "meatFrequency", value: newVal });
      },
    },
  },
  methods: {
    ...mapMutations(["setUserInputs"]),
    setMeatFrequency(frequency) {
      this.meatFrequency = frequency;
    },
  },
};
</script>
<style scoped>
.question-card {
  position: absolute;
  top: 16vh;
  left: 15px;
  right: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 16px;
  font-family: "League Spartan", sans-serif;
}

.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  margin: 10px 0px;
}
.question-card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}
.choices-card {
  position: absolute;
  top: 28vh;
  right: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 14px;
  margin-top: 20px;
  width: fit-content;
  font-family: "League Spartan", sans-serif;
}
</style>
