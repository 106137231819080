<template>
  <div class="ma-0 pa-0 unselectable" v-if="!isLoadingImages">
    <v-row class="col-12 steps-container" no-gutters justify="space-around">
      <v-col
        v-for="step in calculatorSteps"
        :key="step.number"
        class="stepper-indicator 1 my-0 pa-0"
        :class="{
          'stepper-indicator-active':
            step.number <= activeCalculatorStep.number,
        }"
      >
      </v-col>
    </v-row>

    <v-row
      class="col-12 question-header"
      justify="space-between"
      align="center"
    >
      <v-col>
        <div class="question-title">
          Question {{ activeCalculatorStep.number }}
        </div>
      </v-col>
      <v-col cols="auto" align-self="center">
        <v-btn
          v-if="activeCalculatorStep.number != 8"
          text
          dark
          class="question-skip"
          @click="skipCurrentQuestion"
          >skip</v-btn
        >
      </v-col>
    </v-row>

    <div class="previous-step" @click="previousCalculatorStep"></div>
    <div class="next-step" @click="nextCalculatorStep"></div>
    <Question1 v-show="activeCalculatorStep.number == 1" />
    <Question2 v-show="activeCalculatorStep.number == 2" />
    <Question3 v-show="activeCalculatorStep.number == 3" />
    <Question4 v-show="activeCalculatorStep.number == 4" />
    <Question5 v-show="activeCalculatorStep.number == 5" />
    <Question6 v-show="activeCalculatorStep.number == 6" />
    <Question7 v-show="activeCalculatorStep.number == 7" />
    <Question8 v-show="activeCalculatorStep.number == 8" />
    <v-img
      eager
      class="question-image"
      :src="activeCalculatorStep.img"
      lazy-src="https://picsum.photos/id/11/100/60"
    >
    </v-img>
    <div class="icons-parent">
      <div
        v-if="activeCalculatorStep.number != 1"
        class="previous-icon"
        @click="previousCalculatorStep"
      >
        <v-btn icon large color="#333333">
          <v-icon> mdi-chevron-double-left</v-icon>
        </v-btn>
      </div>
      <div
        class="next-icon"
        @click="nextCalculatorStep"
        v-if="activeCalculatorStep.number != 8"
      >
        <v-btn fab color="#ffffff3d" class="transparent-btn">
          <v-icon color="#fff"> mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorMixin from "@/mixins/CalculatorMixin.vue";
import Question1 from "@/components/calculator/Question1.vue";
import Question2 from "@/components/calculator/Question2.vue";
import Question3 from "@/components/calculator/Question3.vue";
import Question4 from "@/components/calculator/Question4.vue";
import Question5 from "@/components/calculator/Question5.vue";
import Question6 from "@/components/calculator/Question6.vue";
import Question7 from "@/components/calculator/Question7.vue";
import Question8 from "@/components/calculator/Question8.vue";
export default {
  mixins: [CalculatorMixin],

  components: {
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
    Question6,
    Question7,
    Question8,
  },
  data() {
    return { isLoadingImages: false };
  },
  methods: {
    skipCurrentQuestion() {
      this.nextCalculatorStep();
    },
  },
};
</script>

<style  scoped>
.steps-container {
  position: absolute;
  top: 4px;
}
.stepper-indicator {
  height: 3px;
  background: rgb(250, 250, 250);
  opacity: 0.5;
  border-radius: 9px;
  margin-right: 5px;
}
.stepper-indicator-active {
  opacity: 1;
}
.question-image {
  background-size: cover;
  width: auto;
  height: 100vh;
  z-index: -1;
}
.previous-step {
  height: 80vh;
  width: 80px;
  position: absolute;
  left: 0;
  bottom: 10%;
}
.next-step {
  height: 80vh;
  width: 80px;
  position: absolute;
  right: 0;
  bottom: 10%;
}
.question-title {
  font-weight: 900;
}
.question-skip {
  color: #ffffff;
  text-transform: capitalize;
}
.question-header {
  font-family: "League Spartan", sans-serif;
  position: absolute;
  top: 18px;
  color: #ffffff;
  font-size: 20px;
  line-height: 16px;
  align-items: center;
}
.icons-parent {
  width: 100%;
  position: relative;
}
.previous-icon {
  position: absolute;
  bottom: 20px;
  left: 10px;
}
.next-icon {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.transparent-btn {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(24px);
  border-radius: 24px;
}
</style>