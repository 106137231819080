<template>
  <div>
    <div>
      <!-- question -->
      <div class="question-card">
        <div class="question-card-title">What size home do you live in?</div>
      </div>
      <!-- choices -->
      <CardWithHeader class="choices-container">
        <ChoiceButton
          :width="200"
          @click.native="setHomeSize('mid-terrace')"
          :isActive="homeSize == 'mid-terrace'"
        >
          Mid-terrace
        </ChoiceButton>
        <ChoiceButton
          :width="200"
          @click.native="setHomeSize('flat')"
          :isActive="homeSize == 'flat'"
        >
          Flat
        </ChoiceButton>
        <ChoiceButton
          :width="200"
          @click.native="setHomeSize('end-terrace')"
          :isActive="homeSize == 'end-terrace'"
        >
          End-terrace
        </ChoiceButton>
        <ChoiceButton
          :width="200"
          @click.native="setHomeSize('semi-detached')"
          :isActive="homeSize == 'semi-detached'"
        >
          Semi-detached
        </ChoiceButton>
        <ChoiceButton
          :width="200"
          @click.native="setHomeSize('detached')"
          :isActive="homeSize == 'detached'"
        >
          Detached
        </ChoiceButton>
      </CardWithHeader>
    </div>
    <!-- slider -->
    <div class="slider-container">
      <CardWithHeader title="Number of occupants">
        <div class="answer-slider-result">{{ occupantsNumberText }}</div>
        <CustomSlider
          icon="family"
          v-model="occupantsNumber"
          :steps="[1, 2, 3, 4, 5]"
        />
      </CardWithHeader>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CardWithHeader from "@/components/calculator/sub-components/CardWithHeader.vue";
import CustomSlider from "@/components/global/CustomSlider.vue";
import ChoiceButton from "@/components/calculator/sub-components/ChoiceButton.vue";

export default {
  name: "Question7",
  components: {
    CardWithHeader,
    CustomSlider,
    ChoiceButton,
  },
  methods: {
    ...mapMutations(["setUserInputs"]),
    setHomeSize(homeSize) {
      this.homeSize = homeSize;
    },
  },
  computed: {
    ...mapGetters(["getUserInputs"]),
    occupantsNumber: {
      get: function () {
        return this.getUserInputs.occupantsNumber;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "occupantsNumber", value: newVal });
      },
    },
    homeSize: {
      get: function () {
        return this.getUserInputs.homeSize;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "homeSize", value: newVal });
      },
    },
    occupantsNumberText() {
      return this.occupantsNumber >= 5
        ? `${this.occupantsNumber}+`
        : `${this.occupantsNumber}`;
    },
  },
};
</script>
<style scoped>
.slider-container {
  position: absolute;
  right: 25px;
  top: 72vh;
}
.question-card {
  position: absolute;
  top: 14vh;
  left: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 6px 20px;
  font-family: "League Spartan", sans-serif;
}
.choices-container {
  position: absolute;
  top: 22vh;
  left: 15px;
}
.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  margin: 10px 0px;
}

/* slider  */
.answer-slider-result {
  font-size: 14px;
  font-weight: 900;
  line-height: 13px;
  text-align: center;
  color: #828282;
  margin-bottom: 15px;
}
</style>
