<template>
  <div class="question-container">
    <!-- question -->
    <div class="question-card">
      <div class="question-card-title">
        How many times a week do you use public transport? 🚌
      </div>
      <div class="question-card-description">
        If you don’t use public transport, tap ‘Skip’.
      </div>
    </div>
    <!-- choices -->

    <div class="choices-card">
      <ChoiceButton
        :width="100"
        @click.native="setPublicTransportUse('1')"
        :isActive="publicTransportUse == 1"
      >
        Once
      </ChoiceButton>
      <ChoiceButton
        :width="100"
        @click.native="setPublicTransportUse('2')"
        :isActive="publicTransportUse == 2"
      >
        Twice
      </ChoiceButton>
      <ChoiceButton
        :width="100"
        @click.native="setPublicTransportUse('3')"
        :isActive="publicTransportUse == 3"
      >
        Thrice
      </ChoiceButton>
      <ChoiceButton
        :width="100"
        @click.native="setPublicTransportUse('4')"
        :isActive="publicTransportUse == 4"
      >
        Forice
      </ChoiceButton>
      <ChoiceButton
        :width="100"
        @click.native="setPublicTransportUse('5')"
        :isActive="publicTransportUse == 5"
      >
        Fice
      </ChoiceButton>
    </div>
  </div>
</template>

<script>
import ChoiceButton from "@/components/calculator/sub-components/ChoiceButton.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Question4",
  components: {
    ChoiceButton,
  },

  computed: {
    ...mapGetters(["getUserInputs"]),
    publicTransportUse: {
      get: function () {
        return this.getUserInputs.publicTransportUse;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "publicTransportUse", value: newVal });
      },
    },
  },
  methods: {
    ...mapMutations(["setUserInputs"]),

    setPublicTransportUse(publicTransportUse) {
      this.publicTransportUse = publicTransportUse;
    },
  },
};
</script>
<style scoped>
.question-card {
  position: absolute;
  top: 16vh;
  left: 15px;
  right: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 16px;
  font-family: "League Spartan", sans-serif;
}

.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  margin: 10px 0px;
}
.question-card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}
.choices-card {
  position: absolute;
  top: 38vh;
  right: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 16px;
  margin-top: 20px;
  width: fit-content;
  font-family: "League Spartan", sans-serif;
}
.choices-card > div > button {
  padding: 29px;
  margin: 6px 0px;
  border-radius: 16px;
  background: #f2f2f2;
  min-width: 90px !important;
  justify-content: start !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
</style>
