<template>
  <div>
    <div class="question-container">
      <!-- question -->
      <div class="question-card">
        <div class="question-card-title">
          Tell us how much you spend per month on:
        </div>
      </div>
    </div>
    <!-- choices -->
    <div class="groceries-slider-container">
      <CardWithHeader title="Groceries">
        <div class="answer-slider-result">{{ shoppingAmountText }}</div>
        <CustomSlider
          icon="shopping"
          :steps="[0, 100, 200, 300, 400, 500]"
          v-model="shoppingAmount"
        />
      </CardWithHeader>
    </div>
    <div class="restaurants-slider-container">
      <CardWithHeader title="Restaurants">
        <div class="answer-slider-result">{{ foodAmountText }}</div>
        <CustomSlider
          icon="food"
          :steps="[0, 100, 200, 300, 400, 500]"
          v-model="foodAmount"
        />
      </CardWithHeader>
    </div>
    <div class="clothes-slider-container">
      <CardWithHeader title="New clothes">
        <div class="answer-slider-result">{{ clothesAmountText }}</div>
        <CustomSlider
          icon="clothes"
          :steps="[0, 100, 200, 300, 400, 500]"
          v-model="clothesAmount"
        />
      </CardWithHeader>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CardWithHeader from "@/components/calculator/sub-components/CardWithHeader.vue";
import CustomSlider from "@/components/global/CustomSlider.vue";

export default {
  name: "Question6",

  components: {
    CardWithHeader,
    CustomSlider,
  },
  computed: {
    ...mapGetters(["getUserInputs"]),
    shoppingAmount: {
      get: function () {
        return this.getUserInputs.shoppingAmount;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "shoppingAmount", value: newVal });
      },
    },
    shoppingAmountText() {
      return this.shoppingAmount >= 500
        ? `£${this.shoppingAmount}+`
        : `£${this.shoppingAmount}`;
    },
    foodAmount: {
      get: function () {
        return this.getUserInputs.foodAmount;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "foodAmount", value: newVal });
      },
    },
    foodAmountText() {
      return this.foodAmount >= 500
        ? `£${this.foodAmount}+`
        : `£${this.foodAmount}`;
    },
    clothesAmount: {
      get: function () {
        return this.getUserInputs.clothesAmount;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "clothesAmount", value: newVal });
      },
    },
    clothesAmountText() {
      return this.clothesAmount >= 500
        ? `£${this.clothesAmount}+`
        : `£${this.clothesAmount}`;
    },
  },
  methods: {
    ...mapMutations(["setUserInputs"]),
  },
};
</script>
<style scoped>
.question-container {
  position: absolute;
  top: 16vh;
  left: 15px;
}

.question-card {
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 6px 20px;
  width: 90%;
  font-family: "League Spartan", sans-serif;
}

.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  margin: 10px 0px;
}

.groceries-slider-container {
  position: absolute;
  left: 40px;
  top: 26vh;
}
.restaurants-slider-container {
  position: absolute;
  left: 130px;
  top: 50vh;
}
.clothes-slider-container {
  position: absolute;
  left: 70px;
  top: 75vh;
}
/* slider  */
.answer-slider-result {
  font-size: 14px;
  font-weight: 900;
  line-height: 13px;
  text-align: center;
  color: #828282;
  margin-bottom: 15px;
  /* padding: 20px; */
  width: 160px;
}
</style>
