
<script>
export default {
  computed: {
    activeCalculatorStep: {
      get: function () {
        return this.$store.getters["activeCalculatorStep"];
      },
      set: function (newVal) {
        this.$store.commit("activeCalculatorStep", newVal);
      },
    },
    activeCalculatorStepNumber: {
      get: function () {
        return this.$store.getters["activeCalculatorStepNumber"];
      },
      set: function (newVal) {
        this.$store.commit("activeCalculatorStepNumber", newVal);
      },
    },
    calculatorSteps() {
      return this.$store.getters["calculatorSteps"];
    },
  },
  methods: {
    nextCalculatorStep() {
      this.$store.commit("nextCalculatorStep");
    },
    previousCalculatorStep() {
      this.$store.commit("previousCalculatorStep");
    },
  },
};
</script>
