<template>
  <div>
    <!-- question -->
    <div class="question-card">
      <div class="question-card-title">Which country do you live in?</div>
      <div class="question-card-description">
        Tell us where you spend most of the year.
      </div>
    </div>
    <!-- choices -->

    <div class="choices-card">
      <ChoiceButton
        @click.native="setCountry('UK')"
        :isActive="country == 'UK'"
      >
        <span><flag code="GB-UKM" /></span> U.K
      </ChoiceButton>

      <ChoiceButton
        @click.native="setCountry('USA')"
        :isActive="country == 'USA'"
      >
        <span><flag code="US" /></span> U.S.A
      </ChoiceButton>

      <ChoiceButton
        @click.native="setCountry('CA')"
        :isActive="country == 'CA'"
      >
        <span><flag code="CA" /></span>Canada
      </ChoiceButton>

      <ChoiceButton
        @click.native="setCountry('AU')"
        :isActive="country == 'AU'"
      >
        <span><flag code="AU" /></span>Australia
      </ChoiceButton>

      <ChoiceButton
        @click.native="setCountry('SE')"
        :isActive="country == 'SE'"
      >
        <span><flag code="SE" /></span>Sweden
      </ChoiceButton>

      <ChoiceButton
        @click.native="setCountry('OTHER')"
        :isActive="country == 'OTHER'"
      >
        <span>🌍</span>Other
      </ChoiceButton>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import ChoiceButton from "@/components/calculator/sub-components/ChoiceButton.vue";
export default {
  name: "Question1",
  components: { ChoiceButton },
  methods: {
    ...mapMutations(["setUserInputs"]),
    setCountry(country) {
      this.country = country;
    },
  },
  computed: {
    ...mapGetters(["getUserInputs"]),
    country: {
      get: function () {
        return this.getUserInputs.country;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "country", value: newVal });
      },
    },
  },
};
</script>
<style scoped>
.question-card {
  position: absolute;
  top: 16vh;
  left: 15px;
  right: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 16px;
  font-family: "League Spartan", sans-serif;
}

.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  margin: 10px 0px;
}
.question-card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}
.choices-card {
  position: absolute;
  top: 30vh;
  left: 15px;
  right: 15px;
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 16px;
  margin-top: 20px;
  width: fit-content;
  font-family: "League Spartan", sans-serif;
}

.choices-card > button > span > span {
  margin-right: 10px !important;
}
</style>
