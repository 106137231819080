<template>
  <div>
    <div class="question-container">
      <!-- question -->
      <div class="question-card">
        <div class="question-card-title">
          How many flights do you take each year?
        </div>
      </div>
    </div>
    <!-- choices -->
    <div class="short-haul-slider-container">
      <CardWithHeader title="Short haul (less than 3 hrs)">
        <div class="answer-slider-result">{{ shortHaulFlightsNumberText }}</div>
        <CustomSlider
          icon="airplane"
          v-model="shortHaulFlightsNumber"
          :steps="[0, 1, 2, 3, 4, 5, 6]"
        />
      </CardWithHeader>
    </div>
    <div class="medium-haul-slider-container">
      <CardWithHeader title="Medium haul (3-6 hrs)">
        <div class="answer-slider-result">
          {{ mediumHaulFlightsNumberText }}
        </div>
        <CustomSlider
          icon="airplane"
          :steps="[0, 1, 2, 3, 4, 5, 6]"
          v-model="mediumHaulFlightsNumber"
        />
      </CardWithHeader>
    </div>
    <div class="long-haul-slider-container">
      <CardWithHeader title="Long haul (6+ hrs)">
        <div class="answer-slider-result">{{ longHaulFlightsNumberText }}</div>
        <CustomSlider
          icon="airplane"
          :steps="[0, 1, 2, 3, 4, 5, 6, 7, 8, 10, 11]"
          v-model="longHaulFlightsNumber"
        />
      </CardWithHeader>
    </div>
  </div>
</template>

<script>
import CardWithHeader from "@/components/calculator/sub-components/CardWithHeader.vue";
import CustomSlider from "@/components/global/CustomSlider.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Question3",

  components: {
    CardWithHeader,
    CustomSlider,
  },
  computed: {
    ...mapGetters(["getUserInputs"]),

    shortHaulFlightsNumber: {
      get: function () {
        return this.getUserInputs.shortHaulFlightsNumber;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "shortHaulFlightsNumber", value: newVal });
      },
    },
    shortHaulFlightsNumberText() {
      if (this.shortHaulFlightsNumber >= 6) {
        return "+5";
      } else {
        return this.shortHaulFlightsNumber;
      }
    },

    mediumHaulFlightsNumber: {
      get: function () {
        return this.getUserInputs.mediumHaulFlightsNumber;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "mediumHaulFlightsNumber", value: newVal });
      },
    },

    mediumHaulFlightsNumberText() {
      if (this.mediumHaulFlightsNumber >= 6) {
        return "+5";
      } else {
        return this.mediumHaulFlightsNumber;
      }
    },

    longHaulFlightsNumber: {
      get: function () {
        return this.getUserInputs.longHaulFlightsNumber;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "longHaulFlightsNumber", value: newVal });
      },
    },
    longHaulFlightsNumberText() {
      if (this.longHaulFlightsNumber >= 11) {
        return "+10";
      } else {
        return this.longHaulFlightsNumber;
      }
    },
  },
  methods: {
    ...mapMutations(["setUserInputs"]),
  },
};
</script>
<style scoped>
.question-container {
  position: absolute;
  top: 16vh;
  left: 15px;
}

.question-card {
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 6px 20px;
  width: 90%;
  font-family: "League Spartan", sans-serif;
}

.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  margin: 10px 0px;
}

.short-haul-slider-container {
  position: absolute;
  right: 85px;
  top: 25vh;
}
.medium-haul-slider-container {
  position: absolute;
  right: 55px;
  top: 44vh;
}
.long-haul-slider-container {
  position: absolute;
  right: 125px;
  top: 64vh;
}
/* slider  */
.answer-slider-result {
  font-size: 14px;
  font-weight: 900;
  line-height: 13px;
  text-align: center;
  color: #828282;
  margin-bottom: 15px;
}
</style>
