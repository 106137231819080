<template>
  <v-btn
    depressed
    class="choice-button"
    :class="{
      'button-active': isActive,
    }"
    x-large
    :width="width || null"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style scoped>
.choice-button {
  margin: 16px 0px !important;
  border-radius: 16px;
  background: #f2f2f2;
  min-width: 183px !important;
  justify-content: start !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: block;
  text-align: center;
  height: 42px !important;
}

.choice-button > span > span {
  margin-right: 10px !important;
}
</style>
