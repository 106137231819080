<template>
  <div>
    <div class="question-container">
      <!-- question -->
      <div class="question-card">
        <div class="question-card-title">If you drive, tell us:</div>
        <div class="question-card-description">
          If you don’t drive, tap ‘Skip’
        </div>
      </div>
      <!-- choices -->
      <CardWithHeader title="Fuel type">
        <ChoiceButton
          :width="200"
          @click.native="setCarburant('diesel')"
          :isActive="carburant == 'diesel'"
        >
          ⛽️ Petrol/Diesel
        </ChoiceButton>
        <ChoiceButton
          :width="200"
          @click.native="setCarburant('hybrid')"
          :isActive="carburant == 'hybrid'"
        >
          ⚡️ Hybrid
        </ChoiceButton>
        <ChoiceButton
          :width="200"
          @click.native="setCarburant('electric')"
          :isActive="carburant == 'electric'"
        >
          🔋 Electric
        </ChoiceButton>
      </CardWithHeader>
    </div>
    <div class="slider-container">
      <CardWithHeader title="Miles driven each year">
        <div class="answer-slider-result">{{ milesDrivenText }}</div>
        <CustomSlider
          icon="car"
          v-model="milesDriven"
          :steps="[0, 5, 10, , 15, 20]"
        />
      </CardWithHeader>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import CardWithHeader from "@/components/calculator/sub-components/CardWithHeader.vue";
import CustomSlider from "@/components/global/CustomSlider.vue";
import ChoiceButton from "@/components/calculator/sub-components/ChoiceButton.vue";

export default {
  name: "Question2",
  components: {
    CardWithHeader,
    CustomSlider,
    ChoiceButton,
  },
  computed: {
    ...mapGetters(["getUserInputs"]),
    milesDriven: {
      get: function () {
        return this.getUserInputs.milesDriven;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "milesDriven", value: newVal });
      },
    },
    milesDrivenText() {
      switch (+this.milesDriven) {
        case 0:
          return "Less than 5K";
        case 5:
          return "5K - 10K";
        case 10:
          return "10K - 15K";
        case 15:
          return "15K - 20K";
        case 20:
          return "More than 20k";
        default:
          return "Less than 5k";
      }
    },

    carburant: {
      get: function () {
        return this.getUserInputs.carburant;
      },
      set: function (newVal) {
        this.setUserInputs({ key: "carburant", value: newVal });
      },
    },
  },
  methods: {
    ...mapMutations(["setUserInputs"]),
    setCarburant(carburant) {
      this.carburant = carburant;
    },
  },
};
</script>
<style scoped>
.question-container {
  position: absolute;
  top: 16vh;
  left: 15px;
}
.slider-container {
  position: absolute;
  right: 55px;
  top: 70vh;
}
.question-card {
  background-color: white;
  line-height: 20px;
  border-radius: 16px;
  padding: 6px 30px;
  font-family: "League Spartan", sans-serif;
}

.question-card-title {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  margin: 10px 0px;
}
.question-card-description {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
}
/* slider  */
.answer-slider-result {
  font-size: 14px;
  font-weight: 900;
  line-height: 13px;
  text-align: center;
  color: #828282;
  margin-bottom: 15px;
}
</style>
